import React, { useContext, useState } from 'react';
import StateContext from '../../context/StateContext'
import GatsbyLink from 'gatsby-link';
import Select from 'react-select';
import Variations from '../Cart/Variations'
import ProductCounter from '../Cart/ProductCounter'
import { navigate } from "gatsby";

export default function AddToCart(props){

  const {
    options
  } = props

  const {
    addToCart
  } = useContext(StateContext)

  const [productCartCount, setProductCartCount] = useState(1);

  const [variation, setVariation] = useState(null);

  const increaseProductAmount = () => {
    let newQuantity = productCartCount + 1;
    setProductCartCount(newQuantity);
  }

  const decreaseProductAmount = () => {
    if (productCartCount === 1) return;
    let newQuantity = productCartCount - 1;
    setProductCartCount(newQuantity);
  }

  const selectVariation = async (variation) => {
    await setVariation(variation)
  }

  let functionArgs = {
    sku: variation,
    quantity: productCartCount
  }


  return (
    <>
    <div class="pro-details-quality pro-details-btns-attchd">
      <Variations options={options} selectVariation={selectVariation} />
      <ProductCounter
        currentAmount={productCartCount}
        decreaseAmount={() => decreaseProductAmount()}
        increaseAmount={() => increaseProductAmount()} />
      <a className="bk-btn bk-btn-cart ml-2" onClick={ (v) => addToCart(functionArgs) }>Add To Cart</a>
    </div>
    </>
  );
}
