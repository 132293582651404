import React, { useState, useCallback, useContext, useEffect, Component } from 'react';
import { Flex, Box, Text } from 'rebass';
import { Helmet } from 'react-helmet';
import { StateConsumer } from '../../context/StateContext'
import ProductContext from '../../context/Product/ProductContext';
import Layout from '../../components/Layout'

// import strings from './strings';
import ReadMore from '../../components/Commons/ReadMore'
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import GatsbyLink from 'gatsby-link';
import { navigate } from "gatsby";
import ConfigureActions from '../../components/Product/ConfigureActions'
import SliderArrow from '../../components/Commons/SliderArrow'
import SocialShare from '../../components/Commons/SocialShare'
import ClearAccordion from '../../components/Commons/ClearAccordion'
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Variations from '../../components/Cart/Variations'
import AddToCart from '../../components/Product/AddToCart'
import { graphql } from 'gatsby'
import {
  nextPage,
  prevPage
} from '../../helpers/GatsbyHelper'
import {
  // createUniqueArrayNumerical,
  crossCheckArray
} from '../../helpers/ContentHelpers'
// import Popover from '@material-ui/core/Popover';
import Img from "gatsby-image"
import Slider from "react-slick";
import Grid from '@material-ui/core/Grid';
import Select from 'react-select';
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import ProductMeta from '../../components/Product/ProductMeta'
import ProductBuilderIcon from '../../components/Commons/ProductBuilderIcon'
import { cleanDrupalMarkup } from '../../helpers/DomHelper'

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
          <Typography>{children}</Typography>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  popover: {
  pointerEvents: 'none',
},
paper: {
  padding: theme.spacing(1),
},

}));



function CatalogProductPage({ data, pageContext, location }) {
  const {
    formlightProduct,
    formlightCatalog,
    allFormlightProduct
  } = data

  const {
    imageGroup,
    nodeBody,
    nodeCategory,
    nodeEnablePb,
    nodeImages,
    nodeName,
    nodePbItems,
    nodeId,
    nodeSticky,
    nodeType,
    pageMeta,
    pageUrl
  } = formlightProduct

  const {
    next,
    prev,
    entityData,
    id,
    categoryId
  } = pageContext;

  const [currentAmount, setCurrentAmount] = useState(1);
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const { options, setProductId } = useContext(ProductContext);

  useEffect(() => {
    setProductId(nodeId);
    return () => {
      setProductId(null);
    };
  }, []);

  //MUI tabs
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // End MUItabs

  const openLightbox = (event, photo, index ) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };



  // console.log(formlightProduct)



  const linkNext = nextPage(allFormlightProduct.nodes, nodeId)
  const linkPrev = prevPage(allFormlightProduct.nodes, nodeId)

  const pbV = entityData.v;
  const entityParents = entityData.p

  // console.log(nodeEnablePb)
  //   console.log("nodeEnablePb")

  const {
    colors: dataColors,
    lens: dataLenses,
    guard: dataGuards,
    fixture: dataFixtures,
    lamp_type: dataLampTypes,
    mounting: dataMounting,
    simple_iten: dataSkus
  } = entityData.v

  let keysLenses = [],
      keysLampTypes = [],
      keysMounting = [],
      keysGuards = new Set(),
      keysWireGuards = new Set(),
      keysGuardsParents = new Set();

  let optionsFixtures = {},
      optionsColors = {},
      optionsLenses = {},
      optionsLensCh = {},
      optionsGuards = {},
      optionsGuardsCh = {},
      optionsLampTypes = {},
      optionsLampTypesCh = {},
      optionsMounting = {},
      optionsMountingCh = {},
      optionsSkus = {};

  nodePbItems.map((item, k) => {

    let itemId = item.key

    // console.log(item)
    // console.log("item")

    if(typeof dataFixtures[itemId] !== "undefined"){

      optionsFixtures[itemId] = dataFixtures[itemId]

      if(dataFixtures[itemId].paint_compatible === 2){
        optionsColors = dataColors
      }

      if(dataFixtures[itemId].lens.length > 0){
        dataFixtures[itemId].lens.map((item, k) => {
          keysLenses.push(item)
        })
      }

      if(dataFixtures[itemId].lamp_types.length > 0){
        dataFixtures[itemId].lamp_types.map((item, k) => {
          keysLampTypes.push(item)
        })
      }

      if(dataFixtures[itemId].mounting_position.length > 0){
        dataFixtures[itemId].mounting_position.map((item, k) => {
          keysMounting.push(item)
        })
      }

      if(dataFixtures[itemId].guard.length > 0) dataFixtures[itemId].guard.map(item => {
        keysWireGuards.add(item)
        keysGuardsParents.add(dataGuards[item].parent_node)
      })

    }

    if(typeof dataSkus[itemId] !== "undefined") {
      // console.log(dataSkus[itemId])
      optionsSkus[itemId] = dataSkus[itemId]
    }
  })


  // console.log(optionsSkus)



  keysLenses = new Set(keysLenses)
  keysLenses = Array.from(keysLenses)
  keysLenses = keysLenses.sort((a,b) => a - b);

  keysLampTypes = new Set(keysLampTypes)
  keysLampTypes = Array.from(keysLampTypes)
  keysLampTypes = keysLampTypes.sort((a,b) => a - b);

  keysMounting = new Set(keysMounting)
  keysMounting = Array.from(keysMounting)
  keysMounting = keysMounting.sort((a,b) => a - b);

  if(keysLenses.length > 0){
    keysLenses.map((item, k) => {


      if(typeof entityParents[item] !== "undefined"){
        optionsLenses[item] = entityParents[item]

        entityParents[item].product_builder.map((item, k) => {
          optionsLensCh[item] = dataLenses[item]
          if(dataLenses[item] && dataLenses[item].guard.length) dataLenses[item].guard.map(item => {
            keysGuards.add(item)
            keysGuardsParents.add(dataGuards[item].parent_node)
          })
        })
      }
    })
  }

  if(keysLampTypes.length > 0){
    keysLampTypes.map((item, k) => {
      if(typeof entityParents[item] !== "undefined") {
        optionsLampTypes[item] = entityParents[item]

      // if(typeof entityParents[item] !== "undefined"){
        entityParents[item].product_builder.map((item, k) => {
          if(typeof dataLampTypes[item] !== "undefined") optionsLampTypesCh[item] = dataLampTypes[item]
        })
      }
    })
  }

  if(keysMounting.length > 0){

    Object.keys(dataMounting).filter(f =>
      crossCheckArray(dataMounting[f].mounting_position, keysMounting)
    ).map((item, k) => {
      optionsMountingCh[item] = dataMounting[item]
    })

    let parentsMounting = []
    Object.keys(optionsMountingCh).map((item, k) => {
      parentsMounting.push(optionsMountingCh[item].parent_node)
    })
    parentsMounting = [...new Set(parentsMounting)]
    parentsMounting.map((item, k) => {
      if(typeof entityParents[item] !== 'undefined') optionsMounting[item] = entityParents[item]
    })

  }

  // const someCastGuards = nodePbItems.some(item => item.cast_guard_compatible === '1' || item.cg_wg_compatible === '1')


  if(keysGuards.size) {
    keysGuards = [...keysGuards]
    keysGuards.map((item, k) => {
      if(typeof dataGuards[item] !== 'undefined') optionsGuardsCh[item] = dataGuards[item]
    })
  }
  if(keysWireGuards.size) {
    keysWireGuards = [...keysWireGuards]
    keysWireGuards.map((item, k) => {
      if(typeof dataGuards[item] !== 'undefined') optionsGuardsCh[item] = dataGuards[item]
    })
  }
  if(keysGuardsParents.size) {
    keysGuardsParents = [...keysGuardsParents]
    keysGuardsParents.map((item, k ) => {
      if(typeof entityParents[item] !== 'undefined') optionsGuards[item] = entityParents[item]
    })
  }

  let mapMagicTabs = {
    fixture: {
      values: optionsFixtures,
      title: 'Fixtures',
      size: 'sm',
    },
    color: {
      values: optionsColors,
      title: 'Colors',
      size: 'sm',
    },
    lens: {
      values: optionsLenses,
      children: optionsLensCh,
      title: 'Lenses',
      size: 'sm',
    },
    guard: {
      values: optionsGuards,
      children: optionsGuardsCh,
      title: 'Guards',
      size: 'lg',
    },
    lampType: {
      values: optionsLampTypes,
      children: optionsLampTypesCh,
      title: 'Lamp types',
      size: 'modal',
    },
    mounting: {
      values: optionsMounting,
      children: optionsMountingCh,
      title: 'Mounting',
      size: 'modal',
    }
  };

  let itemIsConfigurable = false;
  if(nodeType && nodeType === ("Fixture" || "Mounting" || "Lamp Type")) {
    itemIsConfigurable = true;
  }

  let imageObjects = [];

  if(imageGroup !== null && imageGroup.length > 0){
    imageGroup.map((image, i) => {
      let imageSrc = nodeImages[i]
      if(image.childImageSharp !== null) {
        imageSrc = image.childImageSharp.fluid.src
      }
      let imageObject = {
        src: imageSrc,
        height: 1,
        width: 1
      };
      imageObjects.push(imageObject);
    })
  }

  let typeClass = ''
  if(nodeType){
    typeClass = nodeType.toLowerCase()
    typeClass = typeClass.replace(/\s/g,'')
  }

  const settings = {
    customPaging: function(i) {

      let imageSrc = nodeImages[i]
      if(imageGroup[i].childImageSharp !== null) {
        imageSrc = imageGroup[i].childImageSharp.fluid
      }

      return (
        <a>
          {imageGroup[i].childImageSharp !== null && <Img fluid={imageSrc} alt={nodeName} />}
          {imageGroup[i].childImageSharp === null && <img src={imageSrc} alt={nodeName} />}
        </a>
      );
    },
    dots: true,
    dotsClass: "slick-dots slick-thumb product-dec-slider-2 product-dec-left productImgSliderDeck--left",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SliderArrow buttonIcon="dlicon arrows-1_tail-triangle-right" />,
    prevArrow: <SliderArrow buttonIcon="dlicon arrows-1_tail-triangle-left" />,
    responsive: [
      {
        breakpoint: 1380,
        settings: {
          dots: false,
          autoplay: true,
        }
      }
    ]
  };

  let productSkus = nodeName

  if (nodePbItems) {
    productSkus = nodePbItems.map((item, i) => {
      if(nodePbItems.length == i + 1) {
        return (
          <span>{item._c}</span>
        )
      }
      return (
        <span>{item._c}, </span>
      )
    })
  }

  let productSpecPdf = null;
  if(typeof pageMeta.canonical_url !== "undefined"){
    productSpecPdf = pageMeta.canonical_url + '/pdf';
  }

  return (
    <Layout>
      <StateConsumer>
        {({updateModal, modalType, modal}) => {
          return (
            <>
              <div class="ccnCatProdFull product-details-area section border-top pt-80 pt-lg-60 pt-md-50 pt-sm-45 pt-xs-25">

                <div class="container-fluid pl-80 pl-lg-15 pl-md-15 pl-sm-15 pl-xs-15 pr-80 pr-lg-15 pr-md-15 pr-sm-15 pr-xs-15">
                  <div class="ccnProdTop">
                    <div class="ccnProdNav">
                      <div class="row align-items-center">
                        <div class="col-md-6 col-sm-6">
                        <div class="breadcrumb-area">
                          <ul class="page-breadcrumb-three">
                            <li><GatsbyLink to={'/'}>Home</GatsbyLink></li>
                            <li><GatsbyLink to={'/catalog'}>Catalog</GatsbyLink></li>
                            <li><GatsbyLink to={formlightCatalog.pageUrl}>{formlightCatalog.nodeName}</GatsbyLink></li>
                          </ul>
                        </div>
                          {/*nodeType && <h4 className={`product-type product-type--${typeClass}`}>{nodeType}</h4>*/}
                        </div>
                        <div class="col-md-6 col-sm-6">
                          <div class="product-nav">
                            <ul class="d-flex justify-content-end">
                              {linkPrev && <li><GatsbyLink to={linkPrev.pageUrl}><i class="dlicon arrows-1_tail-left"></i></GatsbyLink></li>}
                              {linkNext &&<li><GatsbyLink to={linkNext.pageUrl}><i class="dlicon arrows-1_tail-right"></i></GatsbyLink></li>}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="productTitleWrap">

                      <h2>{nodeName}</h2>
                    </div>
                    <div class="pro-details-size-color">
                      {itemIsConfigurable &&<ConfigureActions array={nodePbItems} arrayItemKey={'key'} arrayItemTitle={'_n'} productId={nodeId} />}
                    </div>
                  </div>
                </div>

                <div class="container container-desktop">
                  <div class="row">
                    <div class="col-12">
                      <div class="--tab-content">
                        <div class="row row-30 ccnRowBreakOut">
                          <div class="col-xl-6 col-12 pb-sm-30 pb-md-50 pb-lg-50">
                            <div class="product-details-tab">
                              <Slider className={`imgC--${nodeImages.length}`} {...settings}>
                                {imageGroup && imageGroup.map((image, k) =>
                                  {

                                    let imageSrc = nodeImages[k]
                                    if(image.childImageSharp !== null) {
                                      imageSrc = image.childImageSharp.fluid
                                    }


                                    return(
                                      <div class="ccn-slick-slide" key={k} onClick={(v) => openLightbox(v, imageSrc, k)}>
                                        {image.childImageSharp !== null && <Img fluid={imageSrc} alt={nodeName} />}
                                        {image.childImageSharp === null && <img src={imageSrc} alt={nodeName} />}
                                        <a class="imgZoomBubble" tabindex="0"><i class="dlicon ui-1_zoom-in"></i></a>
                                      </div>
                                    )
                                  }
                                )}
                              </Slider>
                              <ModalGateway>
                                {viewerIsOpen ? (
                                  <Modal onClose={closeLightbox}>
                                    <Carousel
                                      currentIndex={currentImage}
                                      views={imageObjects.map(x => ({
                                        ...x,
                                        srcset: x.srcSet,
                                        caption: x.title
                                      }))}
                                    />
                                  </Modal>
                                ) : null}
                              </ModalGateway>
                            </div>
                          </div>
                          <div class="col-xl-6 col-12">
                            <div class="product-details-content quickview-content">
                              {nodeBody &&
                                <>
                                  <h3>About {nodeName}</h3>
                                  <ReadMore height={(formlightCatalog.nodeDesc) ? 103 : 190} color="#f8f9f9" title="Read More">{ReactHtmlParser(cleanDrupalMarkup(nodeBody))}</ReadMore>
                                </>
                              }
                              {formlightCatalog.nodeDesc &&
                                <>
                                  <h3>About {formlightCatalog.nodeName}</h3>
                                  <ReadMore height="103" color="#f8f9f9" title="Read More">{ReactHtmlParser(formlightCatalog.nodeDesc)}</ReadMore>
                                </>
                              }

                              {Object.keys(optionsSkus).length > 0 &&
                                <AddToCart options={optionsSkus} />
                              }
                              {/*<ProductMeta />*/}

                              <div class="row">
                                {nodePbItems.length > 10 &&
                                  <div class="col-xl-12">
                                    <div class="pro-details-sku meta-sku meta-ccn">
                                      <span class="ccnLabel">Product codes</span>
                                      <div class="ccnContent">{productSkus}</div>
                                    </div>
                                  </div>
                                }
                                {productSpecPdf !== null &&
                                  <div class="col-xl-4">
                                    <div class="pro-details-meta meta-download meta-ccn">
                                      <span class="ccnLabel">Downloads</span>
                                      <div class="ccnContent">
                                        <ul className="ulMetaPdf">
                                          <li><a className="go" href={productSpecPdf} target="_blank">Download Spec Sheet</a></li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                }
                                {nodePbItems.length < 10 && nodePbItems.length !== 0 &&
                                  <div class="col-xl-4">
                                    <div class="pro-details-sku meta-sku meta-ccn">
                                      <span class="ccnLabel">Product codes</span>
                                      <div class="ccnContent">{productSkus}</div>
                                    </div>
                                  </div>
                                }
                                <div class="col-xl-4">
                                  <div class="pro-details-meta meta-category meta-ccn">
                                    <span class="ccnLabel">Categories</span>
                                    <div class="ccnContent">
                                      <ul><li><GatsbyLink className="go" to={formlightCatalog.pageUrl}>{formlightCatalog.nodeName}</GatsbyLink></li></ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <SocialShare
                                url={pageUrl}
                                title={nodeName}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {nodePbItems.length > 0 && nodeEnablePb == true &&
                <div className="ccnProdBottom pb-100">
                  <div className="container container-desktop">

                    <Tabs className="ccnDescTabs" value={value} onChange={handleChange}>
                      {Object.keys(options).map((item, k) => {

                        let {
                          title,
                          values
                        } = options[item]

                        const TheTitle = () => {
                          return (
                            <div className="ccnDescTabInner" title={title}>
                            <ProductBuilderIcon item={item} />
                            {title}
                            </div>
                          )
                        }

                        let className = 'ccnDescTab'

                        if(Object.keys(values).length === 0) className = 'ccnDescTab hidden'

                        // if (Object.keys(values).length) {
                          return (
                            <Tab className={className} label={<TheTitle/>} {...a11yProps(k)} />
                          )
                        // }
                        // return null
                      })}
                    </Tabs>

                    {Object.keys(options).map((item, k) => {

                      let {
                        title,
                        values,
                        children,
                        size
                      } = options[item]

                      let assignedClassName = item.toLowerCase()
                          assignedClassName = assignedClassName.replace(/\s/g,'')
                          assignedClassName = `partItem-${assignedClassName} partItem-${size}`

                      let gridSize = [
                        12,
                        3,
                        3,
                        3,
                      ]

                      if(size === 'md') {
                        gridSize = [
                          12,
                          12,
                          12,
                          12,
                        ]
                      }

                      if(size === 'lg') {
                        gridSize = [
                          12,
                          2,
                          3,
                          2,
                        ]
                      }


                      if (Object.keys(values).length) {
                        // alert(item)
                      return (
                          <TabPanel value={value} index={k} className="single-product-tab">
                            <Grid container spacing={3} alignItems={'stretch'}>
                              {values && Object.keys(values).map((parent, k) => {

                                let {
                                  i,
                                  _t,
                                  _n,
                                  _d,
                                  _c
                                } = values[parent]

                                if(item === 'fixture') {

                                  let itemDesc = _d !== (null && '')  ? ReactHtmlParser(cleanDrupalMarkup(_d)) : ReactHtmlParser(cleanDrupalMarkup(nodeBody));
                                  let itemName = _n !== (null && '') ? ReactHtmlParser(_n) : ReactHtmlParser(nodeName);
                                  let itemCode = _c !== (null && '') ? _c : 'Configurable';
                                  let itemNode = nodeId

                                  let itemConf = parent
                                  let itemConfStringArgs = ''
                                  let currentPage = 'overview';

                                  let itemType = item;
                                  let itemLink = pbV[itemType][parent];

                                  let partKey = '';

                                  /* This conditional is because we get an error here: /products/custom-pole-top-mounting */
                                  if(itemLink){
                                  /* */
                                    if(itemType === "fixture") {

                                      currentPage = 'overview';
                                      partKey = 'node:'+itemNode+';f';

                                    } else if(itemType === "mounting") {
                                      currentPage = 'm1';

                                      if(itemLink.mounting_stage === 2){
                                        partKey = 'm3';
                                      }
                                      if(itemLink.mounting_stage === 1){
                                        partKey = 'm2';
                                      }
                                      if(itemLink.mounting_stage === 0){
                                        partKey = 'm1';
                                      }

                                    } else if(itemType === "lamp_type") {
                                      currentPage = 'l';
                                      partKey = 'l';

                                    }
                                  /* This conditional is because we get an error here: /products/custom-pole-top-mounting */
                                  }
                                  /* */

                                  itemConfStringArgs = partKey + ':' + itemConf

                                  itemConfStringArgs = itemConfStringArgs+';cp:'+currentPage;
                                  let itemConfString = '/create?b=' + Buffer.from(itemConfStringArgs).toString('base64');

                                  let imageClass = 'configProductItemI--lax';
                                  if(itemIsConfigurable){
                                    imageClass = 'configProductItemI';
                                  }
                                  let titleClass = 'configProductItemT'
                                  if(itemDesc && itemDesc.length === 0){
                                    titleClass = 'configProductItemT--lax'
                                  }

                                  return(
                                    <Grid item xs="12" sm="6">
                                      <div class="configProductItem ccnCard">
                                        <div class="row">
                                          {/*<div class="col-md-3">
                                          <div className={imageClass}>
                                            <img src={nodeImages[0]} />
                                          </div>
                                          </div>
                                          <div class="col-md-9">

                                          {itemIsConfigurable && <div class="configure-btn configProductItemCta">
                                            <GatsbyLink
                                              to={itemConfString}>
                                              Configure
                                            </GatsbyLink>
                                          </div>}
                                          </div>*/}
                                          <div class="col-md-12">
                                            <h4 class={titleClass}><span class="configProductItemC">{itemCode} </span> {itemName}</h4>
                                            <div className="configProductItemD">{itemDesc}</div>
                                          </div>
                                        </div>
                                      </div>
                                    </Grid>
                                  )
                                }
                                if(children && size === 'modal'){
                                  return (
                                    <Grid item xs={gridSize[0]} sm={gridSize[1]}>
                                      <div className={`partItem ${assignedClassName}`}>
                                        <img src={i} />
                                        <div className="partItemTitle">
                                          {_t && <h4 className="partItemCode">{_t}</h4>}
                                          {_n && <h4 className="partItemCode">{_n}</h4>}
                                        </div>
                                      </div>
                                    </Grid>
                                  )
                                }

                                if(children){

                                  let PartItems = (props) => {

                                    // const { number } = props

                                    if(size === 'lg') return (
                                      <ClearAccordion>
                                        {props.children}
                                      </ClearAccordion>
                                    )

                                    return (
                                      <>
                                        {props.children}
                                      </>
                                    )
                                  }

                                  return (
                                    <Grid item xs={12} sm={12} alignItems={'stretch'}>
                                      <div className="partItemSection">
                                        {_t && <h3 className="partItemSectionTitle">{_t}</h3>}
                                        {_n && <h3 className="partItemSectionTitle">{_n}</h3>}
                                        {_d && <p className="partItemSectionDesc">{ReactHtmlParser(_d)}</p>}
                                        <div className="partItems">
                                          {/*<PartItems>*/}
                                            <Grid container spacing={3}>
                                              {Object.values(children).filter(child => child.parent_node == parent).map((item, k) => {

                                                let {
                                                  _c,
                                                  _n,
                                                  _d,
                                                  i
                                                } = item

                                                return (
                                                  <Grid item xs={gridSize[0]} sm={gridSize[1]}>
                                                    <div className={`partItem ${assignedClassName}`}>
                                                      <img src={i} />
                                                      <div className="partItemTitle">
                                                        <h4 className="partItemCode">{_c}</h4>
                                                        <h4 className="partItemName">{_n}</h4>
                                                      </div>
                                                    </div>
                                                  </Grid>
                                                )
                                              })}
                                            </Grid>
                                          {/*</PartItems>*/}
                                        </div>
                                      </div>
                                    </Grid>
                                  )

                                }
                                return (
                                  <Grid item xs={12} sm={3}>
                                    <div className={`partItem ${assignedClassName}`}>
                                      <img src={i} />
                                      <div className="partItemTitle">
                                        <h4 className="partItemCode">{_c}</h4>
                                        <h4 className="partItemName">{_n}</h4>
                                      </div>
                                    </div>
                                  </Grid>
                                )
                              })}
                            </Grid>
                          </TabPanel>
                      )
                    }
                    return null
                    })}
                  </div>
                </div>}
              </div>
            </>
          )}}
      </StateConsumer>
    </Layout>
  );
}

export const query = graphql`
  query formlightProduct($id: Int, $categoryId: Int) {
    formlightProduct(nodeId: {eq: $id}) {
      imageGroup {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      nodeCategory
      nodeEnablePb
      nodeBody
      nodeImages
      nodeId
      nodeName
      nodePbItems {
        key
        _b
        _c
        _n
        _d
        paint_compatible
        cg_wg_compatible
        cast_guard_compatible
        wattage
        lamp_type_specific
        lens_use
        dim_h
        dim_d
        dim_w
        dim_l
      }
      nodeSticky
      nodeType
      pageMeta {
        title
        description
        google
      }
      pageUrl
    }
    allFormlightProduct(filter: {nodeCategory: {eq: $categoryId}}) {
      nodes {
        nodeId
        pageUrl
        nodeName
      }
    }
    formlightCatalog(taxonomyId: {eq: $categoryId}) {
      nodeDesc
      nodeName
      pageUrl
    }
  }
`

export default CatalogProductPage;
