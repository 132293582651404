import React from 'react';

export const nextPage = (allPagesArray, currentId, idKey = 'nodeId') => {

  const total = allPagesArray.length
  const currentIndex = allPagesArray.findIndex(x => x[idKey] === currentId);
  const nextIndex = currentIndex !== total - 1 ? allPagesArray[currentIndex + 1] : allPagesArray[0]

  return nextIndex

}

export const prevPage = (allPagesArray, currentId, idKey = 'nodeId') => {

  const total = allPagesArray.length
  const currentIndex = allPagesArray.findIndex(x => x[idKey] === currentId);
  const prevIndex = currentIndex !== 0 ? allPagesArray[currentIndex - 1] : allPagesArray[total - 1]

  return prevIndex

}
