import React from 'react';
import GatsbyLink from 'gatsby-link';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  details: {
    padding: 0,
  }
}));


export default function ClearAccordion(props){

  const { children } = props
  const classes = useStyles();




  return (
  <Accordion className="ccnClearAccordion">
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel1a-content"
      id="panel1a-header"
    >
      <Typography className={classes.heading}>Accordion 1</Typography>
    </AccordionSummary>
    <AccordionDetails className={classes.details}>
      {children}
    </AccordionDetails>
  </Accordion>
  );
}
