import React, { useContext } from 'react';
import StateContext from '../../context/StateContext'
import GatsbyLink from 'gatsby-link';
import Select from 'react-select';
import { navigate } from "gatsby";

export default function Variations(props){

  const {
    options,
    selectVariation
  } = props

  const { updateModal } = useContext(StateContext)

  let values

  if(typeof options === 'object') {
    values = Object.keys(options).map(item => {
      return ({
        value: options[item].key,
        name: options[item]._n,
        code: options[item]._c,
      })
    })
  }

  let part_select = <Select
    options={values}
    onChange={(v) => onSelect(v)}
    className="ccnVarSel mr-2"
    placeholder={'Select variant'}
    captureMenuScroll={false}
    menuShouldBlockScroll={false}
    getOptionValue={option => `${option.value}`}
    formatOptionLabel={option =>
      <span className="vOptSel">
        <span dangerouslySetInnerHTML={{ __html: option.code }} />
        <span dangerouslySetInnerHTML={{ __html: option.name }} />
      </span>
    }
    // menuIsOpen={true}
    classNamePrefix="ccnVarSel"
  />

 const onSelect = async (v) => {
   await selectVariation(v.code)
 }

  return part_select
}
